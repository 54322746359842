<template>
  <div class="ET7">
    <div class="banner">
      <div class="title">ET 7</div>
      <div class="smallTitle">Smart AI Barcode Scale</div>
      <div class="osDMS">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="video">
      <video
        src="../../../assets/ET 7页面配图/Compax_Et_7_Video.mp4"
        muted
        autoplay
        loop
        webkit-playsinline
        playsinline
      ></video>
    </div>
    <div class="product">
      <div class="top">
        <div class="img">
          <img src="../../../assets/ET 7页面配图/第二页.png" alt="" />
        </div>
      </div>
      <div class="display">
        <div class="title">Display</div>
        <div class="text">15.6” 1366*768</div>
        <div class="text">Capacitive multi-touch screen</div>
      </div>
      <div class="printer">
        <div class="title">Printer</div>
        <div class="text">High speed label printer</div>
      </div>
      <div class="aICamera">
        <div class="title">200M AI Camera</div>
        <div class="text">Support AI recognition</div>
      </div>
      <div class="topline">
        <img src="../../../assets/ET 7页面配图/上.png" alt="" />
      </div>
      <div class="bottom">
        <div class="img">
          <img src="../../../assets/ET 7页面配图/第三页.png" alt="" />
        </div>
      </div>
      <div class="scaleBody">
        <div class="title">Scale body</div>
        <div class="text">Aluminum die-cast</div>
      </div>
      <div class="scalePan">
        <div class="title">Scale pan</div>
        <div class="text">Stainless steel</div>
      </div>
      <div class="bottomline">
        <img src="../../../assets/ET 7页面配图/下.png" alt="" />
      </div>
    </div>
    <div class="ET7con">
      <div class="titleBox">
        <div class="title">ET7</div>
      </div>
      <div class="itemBox">
        <div class="one">
          <div class="itemA">
            <div class="title">OS</div>
            <div class="text">Android 11</div>
          </div>
          <div class="itemA">
            <div class="title">Memory</div>
            <div class="text">RAM 2G</div>
            <div class="text">ROM 16GB</div>
          </div>
          <div class="itemB">
            <div class="title">CPU</div>
            <div class="text">RK3566 Cortex-A55 Quad-core 2.0GHz Max</div>
          </div>
          <div class="itemA">
            <div class="title">SCALE</div>
            <div class="text">Measure range：6/15kg</div>
            <div class="text">Division value：2/5g</div>
            <div class="text">Precision：3000e</div>
          </div>
          <div class="itemA">
            <div class="title">WiFi</div>
            <div class="text">2.4GHz</div>
            <div class="text">Support IEEE 802.11 a/b/g/n</div>
          </div>
        </div>
        <div class="two">
          <div class="left">
            <div class="itemA">
              <div class="title">Display</div>
              <div class="text">Capacitive multi-touch screen</div>
              <div class="text">15.6" HD screen</div>
            </div>
            <div class="itemA">
              <div class="title">Camera</div>
              <div class="text">200M AI Camera</div>
              <div class="text">Support AI recognition</div>
            </div>
          </div>
          <div class="right">
            <div class="itemC">
              <div class="title">Printer</div>
              <div class="text">Direct thermal label printer (60mm)</div>
              <div class="text">Print speed：150mm/s</div>
              <div class="text">56mm print width</div>
              <div class="text">Support 1D & 2D barcodes</div>
            </div>
          </div>
        </div>
        <div class="three">
          <div class="itemA">
            <div class="title">Housing</div>
            <div class="text">Aluminum die-cast scale body</div>
            <div class="text">Stainless steel scale pan</div>
            <div class="text">ABS plastic body</div>
          </div>
          <div class="itemA">
            <div class="title">Ethernet</div>
            <div class="text">10M/100M</div>
          </div>
          <div class="itemD">
            <div class="title">Interface</div>
            <div class="text">USB2.0 x 2 (OTG x 1)</div>
            <div class="text">USB3.0 x 2</div>
            <div class="text">RJ45 x 1</div>
            <div class="text">3.5mm jack</div>
          </div>
          <div class="itemE">
            <div class="top">
              <div class="title">Dimensions</div>
              <div class="text">380mm*545mm*390mm</div>
            </div>
            <div class="bottomImg">
              <div class="left textsmall">545mm</div>
              <div class="right textsmall">390mm</div>
              <div class="bottom textsmall">380mm</div>
              <div></div>
              <div></div>
              <div class="img">
                <img src="../../../assets/ET 7页面配图/ET7.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="two">
          <div class="left">
            <div class="top">
              <div class="itemA">
                <div class="title">Display</div>
                <div class="text">Capacitive multi-touch screen</div>
                <div class="text">15.6" HD screen</div>
              </div>
              <div class="itemA">
                <div class="title">Printer</div>
                <div class="text">Direct thermal label printer (60mm)</div>
                <div class="text">Print speed：150mm/s</div>
                <div class="text">56mm print width</div>
                <div class="text">Support 1D & 2D barcodes</div>
              </div>
              <div class="itemA">
                <div class="title">Camera</div>
                <div class="text">200M AI Camera</div>
                <div class="text">Support AI recognition</div>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div class="itemA">
                  <div class="title">Housing</div>
                  <div class="text">Aluminum die-cast scale body</div>
                  <div class="text">Stainless steel scale pan</div>
                  <div class="text">ABS plastic body</div>
                </div>
                <div class="itemA">
                  <div class="title">Ethernet</div>
                  <div class="text">10M/100M</div>
                </div>
                <div class="itemA">
                  <div class="title">Bluetooth</div>
                  <div class="text">Bluetooth 4.0</div>
                </div>
                <div class="itemA">
                  <div class="title">Power Supply</div>
                  <div class="text">AC IN：</div>
                  <div class="text">AC100-240V; 50-60HZ</div>
                  <div class="text">DC OUT：</div>
                  <div class="text">DC24V/5A</div>
                </div>
                <div class="itemA">
                  <div class="title">Speaker</div>
                  <div class="text">4Ω 3W</div>
                </div>
                <div class="itemA">
                  <div class="title">Environment</div>
                  <div class="text">Operating Environment：</div>
                  <div class="text">Temperature 0~ 40℃</div>
                  <div class="text">Huminity &lt;85%</div>
                </div>
              </div>
              <div class="right">
                <div class="itemC">
                  <div class="title">Interface</div>
                  <div class="text">USB2.0 x 2 (OTG x 1)</div>
                  <div class="text">USB3.0 x 2</div>
                  <div class="text">RJ45 x 1</div>
                  <div class="text">3.5mm jack</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="itemD">
              <div class="top">
                <div class="title">Dimensions</div>
                <div class="text">380mm*545mm*390mm</div>
              </div>
              <div class="bottomImg">
                <div class="left text">545mm</div>
                <div class="right text">390mm</div>
                <div class="bottom text">380mm</div>
                <div></div>
                <div></div>
                <div class="img">
                  <img src="../../../assets/ET 7页面配图/ET7.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.ET7 {
  .banner {
    padding-top: 29px;
    height: 515px;
    background: url('../../../assets/ET 7页面配图/第一页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .video {
    height: 219.42px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .product {
    position: relative;
    background: rgba(28, 28, 28, 1);
    height: 668px;
    .top {
      position: absolute;
      top: 74.73px;
      left: 99.16px;
      .img {
        width: 191.44px;
        height: 243.85px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .title {
      font-size: 12px;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-weight: 700;
    }
    .text {
      line-height: 14.48px;
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
    }
    .display {
      position: absolute;
      top: 10.58px;
      left: 28px;
    }
    .printer {
      text-align: right;
      position: absolute;
      top: 171.58px;
      right: 26.39px;
    }
    .aICamera {
      position: absolute;
      top: 318.43px;
      left: 27.94px;
    }
    .topline {
      position: absolute;
      top: 81.85px;
      left: 48.2px;
      width: 303.34px;
      height: 230.71px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom {
      position: absolute;
      top: 364.49px;
      left: 99.1px;
      .img {
        width: 191.44px;
        height: 243.85px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .scaleBody {
      position: absolute;
      top: 598.62px;
      left: 27.94px;
    }
    .scalePan {
      position: absolute;
      top: 456.58px;
      right: 26.39px;
      .text {
        text-align: end;
      }
    }
    .bottomline {
      width: 303.34px;
      height: 82.73px;
      position: absolute;
      left: 48.2px;
      top: 508.32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ET7con {
    background: rgba(0, 0, 0, 1);
    .titleBox {
      margin-left: 30px;
      padding: 35px 0px 9px 0px;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 53px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 13px;
      padding: 0px 20px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 14.48px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 169px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 221.01px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemE {
        position: relative;
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 226px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }
        .bottomImg {
          color: rgba(255, 255, 255, 1);
          font-size: 8px;
          font-weight: 500;
          position: absolute;
          top: 65.29px;
          left: 37px;
          width: 92.05px;
          height: 134.17px;
          .textsmall {
            font-size: 8px;
          }
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            height: 30px;
            position: absolute;
            top: 8px;
            right: 0px;
          }
          .left {
            line-height: 30px;
            position: absolute;
            bottom: 62.3px;
            left: 3.99px;
          }
          .right {
            position: absolute;
            right: -20.95px;
            bottom: 36px;
          }
          .bottom {
            position: absolute;
            bottom: 0px;
            left: 32px;
          }
        }
      }
      .one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .three {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
    }
  }
}
</style>
